import React from 'react'
import ProductPage from '../../components/ProductPage'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Services',
  },
]

const ServicesPage = () => {
  const top_image =
    process.env.CLOUDINARY_URL + '/f_auto/website/vertical/0b_1920x704_darker_Coworking_wn5bai.jpg'
  const process_image = process.env.CLOUDINARY_URL + '/f_auto/website/2_1920px_DSC5520_kj2hce.jpg'
  const obj_1 = 'website/vertical/Images/06/2_m8c1rs.svg'
  const obj_2 = 'website/vertical/Images/06/3_ahuhqk.svg'
  const obj_3 = 'website/vertical/Images/06/4_vhrxpn.svg'
  const big_content_image = 'website/1c_4to3_backup_-_associate-3548078_1920_t2fxnc.jpg'
  const process_icon_1 = 'website/vertical/request_aelnpm.svg'
  const process_icon_2 = 'website/vertical/assignment_nvfjoa.svg'
  const process_icon_3 = 'website/vertical/Icons/15_positive-vote_ykosr8.png'
  const icon_obj_1 = 'website/vertical/Icons/12_Service_r5pkzz.svg'
  const icon_obj_2 = 'website/vertical/Icons/13_internet_d5ara4.svg'
  const icon_obj_3 = 'website/vertical/Icons/14_mobile_phone_yplpdh.svg'
  const icon_obj_4 = 'website/vertical/Icons/6_recommended_dm8j0s.svg'
  const offer_image_1 = 'website/vertical/Images/footer/e.jpg'
  const offer_image_2 = 'website/vertical/Images/footer/f.jpg'
  const offer_image_3 = 'website/vertical/Images/footer/a.jpg'
  const offer_image_4 = 'website/vertical/Images/footer/b.jpg'
  const offer_image_5 = 'website/vertical/Images/footer/c.jpg'
  const offer_image_6 = 'website/vertical/Images/footer/d.jpg'

  return (
    <ProductPage
      page="product-services"
      top_image={top_image}
      heading_title={'Services'}
      heading_sub_title={'Mehr als nur Handyverträge, Kaffeebohnen und Snacks'}
      catalog_title={'Services'}
      catalog_big_title={'Unsere Services'}
      obj_number={3}
      obj_1={obj_3}
      obj_1_title={'Handyverträge'}
      obj_1_description={'Monatlich kündbare Handyverträge über Lendis'}
      obj_2={obj_1}
      obj_2_title={'Lieferservices'}
      obj_2_description={'Kaffeebohnen, Milch, Snacks, Mittagessen und Getränke direkt ins Büro'}
      obj_3={obj_2}
      obj_3_title={'Büro-Services'}
      obj_3_description={'Reinigungsservice, Internetanschluss & WLAN, Handwerker, Versicherungen'}
      second_catalog_title={'Services'}
      second_catalog_big_title={'Übersicht'}
      big_content_image={big_content_image}
      product_title={'Unser Service-Angebot'}
      product_description={
        'Wir bieten Ihnen alle nötigen Services rund ums Büro – Handyverträge, Internetzugang, Versicherungen, Reinigungsservice, Lieferservice für Getränke, Kaffeebohnen, Snacks oder Lunch.'
      }
      workplace={''}
      price={''}
      period={''}
      icon_obj_1={icon_obj_1}
      bullet_1={'Einfache Lieferung von Kaffeebohnen, Snacks, Getränken und Essen'}
      icon_obj_2={icon_obj_3}
      bullet_3={'Reinigungsservice, Internetanschluss und Versicherungen'}
      icon_obj_3={icon_obj_2}
      bullet_2={'Handyverträge monatlich kündbar'}
      icon_obj_4={icon_obj_4}
      bullet_4={'Alles bequem über Lendis buchen'}
      process_image={process_image}
      process_title={'PROZESS'}
      process_big_title={'So funktionieren Services mit Lendis'}
      process_icon_1={process_icon_1}
      process_title_1={'Anfrage'}
      process_description_1={
        'Sie nennen uns die Service-Wünsche für Ihr Büro, die Mitarbeiterzahl und sonstige Vorstellungen. Wir beraten Sie bei der Auswahl der richtigen Services und erstellen Ihnen ein persönliches Angebot.'
      }
      process_icon_2={process_icon_2}
      process_title_2={'Auftrag'}
      process_description_2={
        'Wählen Sie die Services, die Ihnen zusagen. Während der gesamten Nutzungsdauer bleiben Sie flexibel und können monatlich kündigen oder neue Services buchen und Bestellungen tätigen.'
      }
      process_icon_3={process_icon_3}
      process_title_3={'Service'}
      process_description_3={
        'Lieferungen erhalten Sie in der Regel innerhalb von 24 Stunden. Regelmäßige Services wie Reinigungen aber auch Ihre Handyverträge managen wir bequem für Sie.'
      }
      offer={'ANGEBOT'}
      offer_title={'Weitere Produkte'}
      offer_image_1={offer_image_1}
      offer_title_1={'Elektronik'}
      offer_link_1={'/elektronik/'}
      offer_image_2={offer_image_2}
      offer_title_2={'Software'}
      offer_link_2={'/software/'}
      offer_image_3={offer_image_3}
      offer_title_3={'Büromöbel'}
      offer_link_3={'/bueroeinrichtung/moebel/'}
      offer_image_4={offer_image_4}
      offer_title_4={'Akustik-Lösungen'}
      offer_link_4={'/bueroeinrichtung/akustikloesungen/'}
      offer_image_5={offer_image_5}
      offer_title_5={'Telefonboxen'}
      offer_link_5={'/bueroeinrichtung/telefonboxen/'}
      offer_image_6={offer_image_6}
      offer_title_6={'Pflanzen'}
      offer_link_6={'/bueroeinrichtung/pflanzen/'}
      serviceSection={true}
      breadcrumb={breadcrumb}
    />
  )
}

export default ServicesPage
